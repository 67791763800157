import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import SidenavContent from "./SidenavContent";
import SideDrawer from "components/SideDrawer";
import styles from "./SideNav.module.scss";
import { useMediaQuery } from "hooks/useMediaQuery";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { observer } from "mobx-react-lite";
import sidebarNavStore from "containers/SideNav/sideNavStore";

function SideNav() {
  const { navCollapsed, toggleCollapsedNav } = sidebarNavStore;
  const { isLG } = useMediaQuery();

  let type = "permanent";
  if (!isLG) {
    type = "temporary";
  }

  const isMobileView = type.includes("temporary");
  const isNavBarOpen = isMobileView && navCollapsed;

  useEffect(() => {
    if (!isNavBarOpen) {
      clearAllBodyScrollLocks();
    }
  }, [isNavBarOpen]);

  return (
    <div className={styles.sideNav}>
      {!isMobileView && <SidenavContent />}
      {isNavBarOpen && (
        <SideDrawer
          isOpen={isNavBarOpen}
          onClose={toggleCollapsedNav}
          isDisableOutsideClick={false}
          isLeftSideLayout
          isShowLayout={false}
          showCloseBtn={false}
          showHeader={false}
          containerClassName="w-full"
          outerClassName="sidebarContainer"
        >
          <SidenavContent showCloseIcon={isNavBarOpen} />
        </SideDrawer>
      )}
    </div>
  );
}

export default withRouter(observer(SideNav));
